import React, { PureComponent } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import withUser from '../../hocs/with-user';
import withUserInfo from '../../hocs/with-user-info';
import withAPI from '../../hocs/with-api';
import Logo from '../../components/logo';
import Errors from '../../components/notifications/errors';
import { navigation } from 'components/navigation';
import { checkNavPermission } from 'utils';
import _ from 'lodash';
import DownloadApp from 'components/download-apps/download-apps';
import { SuccessMessage, ValidationsErrors } from 'components/notifications';
import ReactRecaptcha3 from 'react-google-recaptcha3';

class AuthComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      loginEnabled: true,
      loginError: [],
      logo: '',
      isBrandLoading: true,
      isRememberMe: false,
      notification: {},
    };
  }

  componentDidMount() {
    document.title = `Signin/Login - ${process.env.REACT_APP_NAME}`;
    window.onpopstate = this.onBackButtonClick;
    this.initRecaptcha();
    this.loadBrand();
    this.setState({ isRememberMe: Boolean(+localStorage.getItem('isRememberMe')) });
    this.setNotifications();
  }

  initRecaptcha = () => {
    if (process.env.REACT_APP_RECAPTCHA_SITEKEY) {
      this.setState({ loginEnabled: false, isLoading: true });
      ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITEKEY).then(status => {
        if (status === 'success') {
          this.setState({
            isLoading: false,
            loginEnabled: true,
          });
        } else {
          this.showRecaptchaError('Failed to load reCAPTCHA, please refresh the page.');
        }
      });
    }
  }

  componentWillUnmount() {
    window.onpopstate = null;
    ReactRecaptcha3.destroy();
  }

  setNotifications = () => {
    const { history } = this.props;

    const notification = {}
    if (_.hasIn(history.location.state, 'error')) {
      notification.error = history.location.state.error;
    }
    if (_.hasIn(history.location.state, 'success')) {
      notification.success = history.location.state.success;
    }
    this.setState({ notification });
  }

  onSubmit = async (recaptchaToken = '') => {
    const {
      history,
      login,
      storeTokens,
      storeProfile,
      setIsAuthenticated,
      getProfile,
    } = this.props;

    const {
      email,
      password,
    } = this.state;

    if (email && password) {
      this.setState({ loginEnabled: false, isLoading: true });
      const tokens = await login({ email, password, recaptchaToken }).catch((error) => {
        this.setState({
          isLoading: false,
          loginEnabled: true,
          loginError: [error.response.data.message || 'Something went wrong'],
        });
      });
      if (tokens) {
        localStorage.setItem('canReLogin', '1');
        storeTokens(tokens);
        const profile = await getProfile();
        storeProfile(profile);
        setIsAuthenticated(true);
        const permissions = _.get(profile, 'data.permissions', []);
        const filteredNavigation = navigation.filter((nav) => checkNavPermission(nav, permissions));
        const firstTabRef = _.get(filteredNavigation, '[0].href', '/home');
        history.push(firstTabRef);
      }
    } else {
      const loginError = [];
      if (!email) loginError.push('Email address field is required');
      if (!password) loginError.push('Password field is required');
      this.setState({
        isLoading: false,
        loginEnabled: true,
        loginError,
      });
    }
  }

  onSubmitClick = async (event) => {
    event.preventDefault();
    if (process.env.REACT_APP_RECAPTCHA_SITEKEY) {
      this.setState({ loginEnabled: false, isLoading: true });
      ReactRecaptcha3.getToken().then(token => {
        this.onSubmit(token);
      }, error => {
        this.setState({ loginEnabled: true, isLoading: false });
        this.showRecaptchaError();
      })
    } else {
      this.onSubmit();
    }
  };

  showRecaptchaError = (message = 'Failed reCAPTCHA check, please try again.') => {
    const loginError = [];
    loginError.push(message);
    this.setState({
      loginError,
    });
  }

  onEmailChanged = (event) => {
    this.setState({ email: event.target.value });
  };

  onPasswordChanged = (event) => {
    this.setState({ password: event.target.value });
  };

  loadBrand = async () => {
    const { httpRequest } = this.props;

    const response = await httpRequest({
      method: 'get',
      url: 'domain/brand',
    }).catch(() => this.setState({ isBrandLoading: false }));
    if (response) {
      const {
        data: {
          data,
        },
      } = response;

      const { commercial_name, logo } = data || {};
      if (commercial_name) document.title = `Signin/Login - ${commercial_name}`;
      if (logo) this.setState({ logo, isBrandLoading: false });
    } else this.setState({ isBrandLoading: false });
  }

  onChangeRememberMe = () => {
    const { isRememberMe } = this.state;
    localStorage.setItem('isRememberMe', isRememberMe ? 0 : 1);
    this.setState({ isRememberMe: !isRememberMe });
  }

  render() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) return <Redirect to="/home" />;
    const {
      loginEnabled, loginError, isLoading, logo, isBrandLoading, isRememberMe,
      notification,
    } = this.state;

    return (
      <div className="min-h-full h-full">

        <div className="min-h-full h-full flex lg:grid lg:grid-cols-2">
          <div className="flex-1 flex flex-col justify-center pt-10 py-2 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto mt-auto w-full lg:w-96">
              <div>
                {!isBrandLoading && <Logo brand_logo={logo} />}
                <br />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in</h2>
                {
                  loginError.length > 0 && (
                    <Errors errors={loginError} />
                  )
                }
                {!_.isEmpty(notification.error) && (
                  <ValidationsErrors errors={notification.error} />
                )}
                {!_.isEmpty(notification.success) && (
                  <SuccessMessage message={notification.success} />
                )}
              </div>

              <div className="mt-6 relative">
                {isLoading && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="animate-spin border-b-2 rounded-full h-10 w-10 border-gray-900" />
                  </div>
                )}
                <div className="mt-6">
                  <form
                    action="#"
                    method="POST"
                    className={`${isLoading ? 'opacity-50' : ''} space-y-6`}
                  >
                    <div>

                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm
                        focus-within:ring-1 focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                          Email address
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                            placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="tail@email.com"
                          onChange={this.onEmailChanged}
                        />
                      </div>
                    </div>

                    <div className="space-y-1">
                      <div className="border border-gray-300 rounded-md px-3 py-2 shadow-sm
                        focus-within:ring-1 focus-within:ring-green-regular focus-within:border-green-regular"
                      >
                        <label htmlFor="password" className="block text-xs font-medium text-gray-900">
                          Password
                        </label>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          className="block w-full border-0 outline-none p-0 bg-transparent text-gray-900
                            placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="Enter password"
                          onChange={this.onPasswordChanged}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          checked={isRememberMe}
                          onChange={this.onChangeRememberMe}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm">
                        <Link to="/password/reset" className="font-medium text-green-regular hover:text-green-hover">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent
                        rounded-md shadow-sm text-sm font-medium text-white bg-green-regular
                        hover:bg-green-hover focus:outline-none focus:ring-2 focus:ring-offset-2
                        focus:ring-green-regular"
                        onClick={this.onSubmitClick}
                        disabled={!loginEnabled || isLoading}
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="mt-auto mx-auto w-full">
              <DownloadApp />
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1 py-8 px-12 w-full text-center bg-green-light">
            <img
              className="inset-0 w-full object-cover mx-auto login-img"
              src="/assets/login-image.jpg"
              alt=""
            />
            <Link
              to="/"
              className="bg-white color-green-regular hover:text-green-hover
          rounded-md px-6 py-3 inline-block"
            >
              Get started
            </Link>

          </div>
        </div>

      </div>
    );
  }
}

AuthComponent.propTypes = {
  login: PropTypes.func.isRequired,
  storeTokens: PropTypes.func.isRequired,
  storeProfile: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  httpRequest: PropTypes.func.isRequired,
};
export default compose(withUser, withUserInfo, withRouter, withAPI)(AuthComponent);
